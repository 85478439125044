import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
          <div >
          <video src="../img/introvid.mp4" autoPlay muted loop playsInline width="100%" />
            <div >
          </div>
        </div>
      </div>
    </header>
  );
};
